.details {
  margin: 20px;
}

.block {
  margin-top: 20px;
}

:global {
  .ant-tag {
    white-space: pre-wrap;
  }
}

.mt4{
  margin-top: 40px;
}

.textCenter{
  text-align: center;
}

.row {
  margin-bottom: 5px;
}

.key {
  margin-right: 8px;
  font-weight: 700;
}

.spinner {
  text-align: center;
  padding-top: 200px;
}