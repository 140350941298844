.App {
  text-align: center;
  margin: 5px;

  .header {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    padding: 5px 10px;
    left: 0;
    margin: 5px;
    background: white;
  }
  
  .results {
    padding-top: 40px;
    margin: 20px;
  }
  
  .loading {
    opacity: 0.5;
  }
}

:global {
  .ant-card-meta-description,
  .ant-card-meta-title {
    text-align: left;
  }
  .List {
    width: 100% !important;
  }
}