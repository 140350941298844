.movieItem {
  cursor: pointer;
  width: 100%;
}

.moviePoster {
  width: 100px;
  height: 100px;
}

.mr6 {
    margin-right: 6px;
}

:global {
  .ant-card.ant-card-bordered {
    width: 100%;
  }
}